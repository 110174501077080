import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'urql'

import {
  CanApplyPromoCodeQuery,
  CanApplyPromoCodeQueryVariables,
  PromoCodeOutput,
} from '@app/generated/graphql'
import { CAN_APPLY_PROMO_CODE } from '@app/modules/course_booking/queries/can-apply-promo-code'

import type { Discounts } from '../BookingContext'

type Props = {
  codes: string[]
  discounts: Discounts
  courseId: number
  onAdd: (_: PromoCodeOutput) => void
  onRemove: (_: string) => void
}

export const PromoCode: React.FC<React.PropsWithChildren<Props>> = ({
  codes,
  discounts,
  courseId,
  onAdd,
  onRemove,
}) => {
  const { t } = useTranslation()
  const [adding, setAdding] = useState(false)
  const [value, setValue] = useState('')
  const [applyError, setApplyError] = useState('')

  const [{ data: response, error }] = useQuery<
    CanApplyPromoCodeQuery,
    CanApplyPromoCodeQueryVariables
  >({
    query: CAN_APPLY_PROMO_CODE,
    variables: { input: { code: value.trim(), courseId } },
  })

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setValue(e.target.value)

      if (applyError !== '') {
        setApplyError('')
      }
    },
    [applyError],
  )

  const handleCancel = () => {
    setAdding(false)
  }

  const handleApply = useCallback(async () => {
    if (error) {
      return setApplyError(t('invalid-promo-code'))
    }
    if (!response?.canApplyPromoCode.result) {
      return setApplyError('Code cannot be applied')
    }
    if (!codes.find(c => c === response.canApplyPromoCode.result?.code)) {
      onAdd(response.canApplyPromoCode.result)
    }
    setValue('')
    setAdding(false)
  }, [codes, error, onAdd, response?.canApplyPromoCode.result, t])

  return (
    <Stack>
      <Box>
        {codes.map(c => (
          <Box
            key={c}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <Typography
                variant="body1"
                color="grey.700"
                textTransform="uppercase"
              >
                {t('code')}: {c}
              </Typography>
              <LoadingButton
                loading={false}
                variant="text"
                color="primary"
                size="small"
                sx={{ ml: 2 }}
                onClick={() => onRemove(c)}
              >
                {t('remove')}
              </LoadingButton>
            </Box>
            <Typography
              variant="body1"
              color="grey.700"
              data-testId="promo-code-discount"
            >
              - {t('currency', { amount: discounts[c]?.amountCurrency ?? 0 })}
            </Typography>
          </Box>
        ))}
      </Box>

      {adding && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box pr={2} flex={1}>
            <TextField
              autoFocus
              variant="filled"
              label={t('enter-promo-code')}
              placeholder={t('promo-code')}
              fullWidth
              sx={{ bgcolor: 'grey.100' }}
              onChange={onChange}
              value={value}
              error={applyError !== ''}
            />
            {applyError !== '' ? (
              <FormHelperText>{applyError}</FormHelperText>
            ) : null}
          </Box>
          {value.trim().length ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleApply}
            >
              {t('apply')}
            </Button>
          ) : (
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={handleCancel}
            >
              {t('cancel')}
            </Button>
          )}
        </Box>
      )}

      {!adding && !codes.length ? (
        <Box display="flex" justifyContent="flex-end" mr={-1} mt={2}>
          <Button
            size="small"
            variant="text"
            color="primary"
            sx={{ fontWeight: '600' }}
            onClick={() => setAdding(true)}
          >
            {t('pages.book-course.apply-promo')}
          </Button>
        </Box>
      ) : null}
    </Stack>
  )
}
